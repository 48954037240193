<template>
  <vs-popup fullscreen title="Detalle" :active.sync="popupActiveLocal">
      <ag-grid-vue
          ref="popupDetails"
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          :rowData="items"
          colResizeDefault="shift"
          :animateRows="true"
          :pagination="false"
          :suppressPaginationPanel="true"
          :enableRtl="$vs.rtl">
      </ag-grid-vue>
  </vs-popup>
</template>
<script>
import { AgGridVue } from "@ag-grid-community/vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

export default {
  props: {
      data: {
          type: Array,
          required: true
      },
      popupActive: {
          type: Boolean,
          required: true
      }
  },
  data() {
      return {
          gridOptions: {
              alignedGrids: [],
              suppressHorizontalScroll: true,
          },
          defaultColDef: {
              sortable: true,
              editable: false,
              resizable: true,
              suppressMenu: true
          },
          columnDefs: [
              {
              headerName: 'ID',
              field: 'id',
              filter: true,
              width: 120,
              // hide: this.$acl.check('store')
              },
              {
              headerName: 'Fecha',
              field: 'sale_date',
              filter: true,
              },
              {
              headerName: 'Comprador',
              field: 'buyer_name',
              filter: true,
              valueGetter: params => {
                  return params.data.buyer_last_name ? `${params.data.buyer_name} ${params.data.buyer_last_name}` : ''
                }
              },
              {
              headerName: 'Receptor',
              field: 'receiver_name',
              filter: true,
              valueGetter: params => {
                  return params.data.receiver_last_name ? `${params.data.receiver_name} ${params.data.receiver_last_name}` : ''
                }
              },
              {
              headerName: 'SKU',
              field: 'sku',
              filter: true,
              },
              {
              headerName: 'Producto',
              field: 'title',
              filter: true,
              },
              {
              headerName: 'Valor Unitario',
              field: 'price',
              filter: true,
              valueFormatter: params => this.$options.filters.currencySign(params.data.base_amount, '$ '),
              },
              {
              headerName: 'Cantidad',
              field: 'quantity',
              filter: true,
              },
              {
              headerName: 'Total',
              field: 'subtotal',
              filter: true,
              valueFormatter: params => this.$options.filters.currencySign(params.data.subtotal, '$ '),
              },
          ],
          // popupActiveLocal: JSON.parse(this.popupActive)
      }
  },
  components: {
      AgGridVue,
  },
  computed: {
      popupActiveLocal: {
          get() {
              return JSON.parse(this.popupActive)
          },
          set() {
              this.$emit('updatePopupActive')
          }
      },
      items() {
          return this.data
      }
  },
}
</script>
