<template>
  <div class="flex align-center" :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
    <!-- <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
      <feather-icon icon="RefreshCwIcon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" v-if="this.params.status == 4"/> -->
    <vx-tooltip v-if="$acl.check('school') || $acl.check('store')" text="Ver Detalle">
      <feather-icon icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="clickHandler()" />
    </vx-tooltip>
    <vx-tooltip v-if="params.data.total_pay > 0 || params.data.final_amount_collector_total > 0 || params.data.final_amount > 0" text="Solicitar Pago">
      <feather-icon icon="DollarSignIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="confirmSendData()" />
    </vx-tooltip>
    <!-- <vs-button v-show="this.params.data.total_pay > 0 || this.params.data.commission_amount > 0 && this.params.data.status != 0" @click="confirmSendData()">Solicitar pago</vs-button> -->
    <span v-show="(this.params.data.type == 0 || this.params.data.type == 1) && this.params.data.status == 0">Pendiente</span>
  </div>
</template>

<script>
export default {
  name: "CellRendererActions",
  methods: {
    editRecord() {
      // this.$router.push("/views/user/user-edit/" + 268).catch(() => {})
      this.$router
        .push("/users/user-edit/" + this.params.data.id)
        .catch((error) => {
          console.log(error);
        });
      /*
              Below line will be for actual product
              Currently it's commented due to demo purpose - Above url is for demo purpose

              this.$router.push("/apps/user/user-edit/" + this.params.data.id).catch(() => {})
            */
    },
    confirmSendData() {
      this.$vs.dialog({
        type: "confirm",
        color: "warning",
        title: `Confirmar Solicitud`,
        text: `¿Estás seguro que quieres realizar esta solicitud?`,
        accept: this.sendData,
        acceptText: "Aceptar",
      });
    },
    sendData() {
      var requestpay = this.$acl.check('owner') ? { id_owner: this.$store.state.AppActiveUser.account.id, id_institution: this.params.data.id_institution,} : this.$acl.check('school') ? { id_owner: this.$store.state.AppActiveUser.account.id, id_establishment: this.params.data.id_establishment,} : {type: this.params.data.type, request_date: new Date().toISOString().split("T")[0]};

      if(this.$acl.check('owner')) {
        this.$store.dispatch("reportModule/posRequestPay", requestpay)
        .then((response) => {
          if(response.status){
            this.showSuccess();
          this.$router.push("/reports/requests")
          }
        })
        .catch((error) => {
          this.showError(error);
        });
      }

      if(this.$acl.check('superadmin')) {
        this.$store.dispatch("reportModule/requestPaymentSuperAdmin", requestpay)
        .then((response) => {
          if(response.status){
            this.showSuccess();
          this.$router.go()
          }
        })
        .catch((error) => {
          this.showError(error);
        });
      }

      if(this.$acl.check('school')) {
        this.$store.dispatch("reportModule/serviceRequestPay", requestpay)
        .then((response) => {
          if(response.status){
            this.showSuccess();
            this.$router.push("/reports/requests")
          }
        })
        .catch((error) => {
          this.showError(error);
        });
      }

      if(this.$acl.check('store')) {
        this.$store.dispatch("reportModule/addMarketplacePaymentRequest", this.$store.state.AppActiveUser.store.id)
        .then((response) => {
          if(response.status) {
            this.showSuccess();
            this.$router.push("/marketplace/reports/requests")
          }
        })
        .catch((error) => {
          this.showError(error);
        });
      }

    },
    showSuccess() {
      this.$vs.notify({
        time: 8000,
        color: "success",
        title: "Solicitud enviada",
        text: "Se ha enviado correctamente la solicitud",
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-check',
      });
    },
    showError(msg) {
      this.$vs.notify({
        time: 8000,
        color: "danger",
        title: "ERROR",
        text: `${msg}`,
        position: 'top-center',
        iconPack: 'feather',
        icon:'icon-alert-circle',
      });
    },
    clickHandler() {
      // this.$router.push("/reports/service/details/" + this.params.data.id_main_institution).catch((error) => {console.log(error)})
      this.params.showPopup(this.params.data.detail)
    }
  },
};
</script>
