<template>

  <div id="page-payments">

    <div v-if="$acl.check('owner')" class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ payments.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : payments.length }} de {{ payments.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Buscar..." />
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Exportar</vs-button>
      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="payments"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :modules="modules">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage" />

    </div>

    <div v-if="$acl.check('superadmin')" class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ payments.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : payments.length }} de {{ payments.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Buscar..." />
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Exportar</vs-button>
      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefsSuperAdmin"
        :defaultColDef="defaultColDef"
        :rowData="payments"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :modules="modules">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage" />

    </div>

    <div v-if="$acl.check('school')" class="vx-card p-6">
      <div class="w-full">
        <h5 class="my-2">Solicitudes de Pago - Servicios Únicos</h5>
        <!-- <h5 class="my-2">Solicitudes de Pago - Servicios Únicos (deprecado)</h5> -->
        <span class="py-3">Solicitud de pago por Servicios Únicos.</span>
        <!-- <span class="py-3">Solicitud de pago por Servicios Únicos (módulo deprecado en junio 2024).</span> -->
      </div>
      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ payments.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : payments.length }} de {{ payments.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Buscar..." />
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Exportar</vs-button>
      </div>


      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefsSchool"
        :defaultColDef="defaultColDef"
        :rowData="payments"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :modules="modules">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage" />

    </div>

    <div v-if="$acl.check('store')" class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ payments.length - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : payments.length }} de {{ payments.length }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="gridApi.paginationSetPageSize(50)">
                <span>50</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Buscar..." />
          <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Exportar</vs-button>
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefsStore"
        :defaultColDef="defaultColDef"
        :rowData="payments"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
        :modules="modules">
      </ag-grid-vue>

      <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage" />

    </div>

    <popup-detail
        v-if="$acl.check('school')"
        :data="popupData"
        :popupActive="popupActive"
        @updatePopupActive="updatePopupActive(false)"
    ></popup-detail>
    <popup-sales-detail
        v-if="$acl.check('store')"
        :data="popupData"
        :popupActive="popupActive"
        @updatePopupActive="updatePopupActive(false)"
    ></popup-sales-detail>
  </div>

</template>

<script>
import { AgGridVue } from "@ag-grid-community/vue"
import {AllCommunityModules} from '@ag-grid-community/all-modules';
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'
import PopupDetail from '@/views/reports/incomes/popup/IncomeServiceDetailsPopup.vue'
import PopupSalesDetail from '@/views/marketplace/components/SalesDetailPopup.vue'

// Store Module
import reportModule from '@/store/report/moduleReport.js'

// Cell Renderer
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"

export default {
  components: {
    AgGridVue,
    vSelect,

    // Cell Renderer
    CellRendererActions,

    // Popups
    PopupDetail,
    PopupSalesDetail
  },
  data() {
    return {
        modules: AllCommunityModules,

        payments: [],
        searchQuery: "",

        // AgGrid
        gridApi: null,
        gridOptions: {},

        defaultColDef: {
            sortable: true,
            resizable: false,
            suppressMenu: true
        },

        columnDefs: [
            {
                headerName: "ID",
                field: "id_institution",
                width: 80,
                filter: true,
            },
            {
                headerName: "Cafeteria",
                field: "name_institution",
                width: 350,
                filter: true,
            },
            {
                headerName: "Ventas Tiptap",
                field: "tiptap_total",
                width: 180,
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.tiptap_total, '$ '),
            },
            {
                headerName: "Recargas Efectivo",
                field: "recharges_total",
                width: 180,
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.recharges_total, '$ '),
            },
            {
                headerName: "Comisión Tiptap",
                field: "tiptap_commission",
                width: 120,
                filter: true,
                valueFormatter: params => this.$options.filters.currencyPercentage(params.data.tiptap_commission, ' %'),
            },
            {
                headerName: "Comisión Total",
                field: "commission_total",
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.commission_total, '$ '),
            },
            {
                headerName: "Total a Cobrar",
                field: "total_pay",
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.total_pay, '$ '),
            },
            {
                headerName: "Acción",
                filter: true,
                cellRendererFramework: 'CellRendererActions',
            },
        ],
        columnDefsSuperAdmin: [
            {
                headerName: "ID",
                field: "id",
                width: 80,
                filter: true,
            },
            {
                headerName: "Administrador",
                field: "admin_name",
                width: 350,
                filter: true,
            },
            {
                headerName: "Tipo de Pago",
                field: "type_description",
                width: 180,
                filter: true,
            },
            {
                headerName: "Total",
                field: "total_amount",
                width: 180,
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.total_amount, '$ '),
            },
            {
                headerName: "Comisión",
                field: "commission",
                width: 120,
                filter: true,
                valueFormatter: params => this.$options.filters.currencyPercentage(params.data.commission, ' %'),
            },
            {
                headerName: "Total a Cobrar",
                field: "commission_amount",
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.commission_amount, '$ '),
            },
            {
                headerName: "Fecha de Solicitud",
                field: "created_at",
                filter: true,
            },
            {
                headerName: "Acción",
                filter: true,
                cellRendererFramework: 'CellRendererActions',
            },
        ],
        columnDefsSchool: [
            {
                headerName: "ID",
                field: "id_establishment",
                width: 80,
                filter: true,
            },
            {
                headerName: "Escuela",
                field: "name_establishment",
                width: 350,
                filter: true,
            },
            {
                headerName: "Pagos por Servicios",
                field: "base_amount_total",
                width: 220,
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.base_amount_total, '$ '),
            },
            {
                headerName: "$ Comisión Tiptap",
                field: "tiptap_amount_commission_main_institution",
                width: 180,
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.tiptap_amount_commission_main_institution, '$ '),
            },
            {
                headerName: "Total a Cobrar",
                field: "final_amount_collector_total",
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.final_amount_collector_total, '$ '),
            },
            {
                headerName: "Acción",
                filter: true,
                cellRendererFramework: 'CellRendererActions',
                cellRendererParams: {
                  showPopup: this.showPopup.bind(this)
                }
            },
        ],
        columnDefsStore: [
            {
                headerName: "ID",
                field: "id_commerce",
                width: 80,
                filter: true,
            },
            {
                headerName: "Tienda",
                field: "store_name",
                width: 350,
                filter: true,
            },
            {
                headerName: "Total Vendido",
                field: "base_amount",
                width: 220,
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.base_amount, '$ '),
            },
            {
                headerName: "$ Comisión Tiptap",
                field: "tiptap_commission_amount",
                width: 180,
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.tiptap_commission_amount, '$ '),
            },
            {
                headerName: "Total a Cobrar",
                field: "final_amount",
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.final_amount, '$ '),
            },
            {
                headerName: "Acción",
                filter: true,
                cellRendererFramework: 'CellRendererActions',
                cellRendererParams: {
                  showPopup: this.showPopup.bind(this)
                }
            },
        ],

        // Cell Renderer Components
        components: {
            CellRendererActions,
        },

        // Popup Details
        popupData: [],
        popupActive: false,
    }
  },
  watch: {
    roleFilter(obj) {
      this.setColumnFilter("role", obj.value)
    },
    statusFilter(obj) {
      this.setColumnFilter("status", obj.value)
    },
    isVerifiedFilter(obj) {
      let val = obj.value === "all" ? "all" : obj.value == "yes" ? "true" : "false"
      this.setColumnFilter("is_verified", val)
    },
    departmentFilter(obj) {
      this.setColumnFilter("department", obj.value)
    },
  },
  computed: {
    paginationPageSize() {
      if(this.gridApi) return this.gridApi.paginationGetPageSize()
      else return 20
    },
    totalPages() {
      if(this.gridApi) return this.gridApi.paginationGetTotalPages()
      else return 0
    },
    currentPage: {
      get() {
        if(this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1
        else return 1
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1)
      }
    }
  },
  methods: {
    getPayments() {
      if(this.$acl.check('owner')) {
        this.$store.dispatch("reportModule/getRequestPay",this.$store.state.AppActiveUser.account.id)
        .then((response) => {
          if (response.data.length > 0) {
            this.payments = response.data; //.filter(p => p.status_institution == 0 && p.tiptap_total == null );
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }

      if(this.$acl.check('superadmin')) {
        this.$store.dispatch("reportModule/getRequestPaySuperAdmin",this.$store.state.AppActiveUser.account.id)
        .then((response) => {
          if (response.data.payments.length > 0) {
            let resp = JSON.parse(JSON.stringify(response.data.payments));

            let p = resp.map(p => {
              p.admin_name = response.data.profile.name + ' ' + response.data.profile.last_name;
              p.type_description = p.type == 1 ? "Anualidad" : "Ventas Tiptap";
              if(p.created_at)
                p.created_at = new Date(p.created_at).toLocaleDateString("es-AR", this.$localeDateOptions);
              return p;
            });

            this.payments = p;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }

      if(this.$acl.check('school')) {
        this.$store.dispatch("reportModule/getRequestPayServices",this.$store.state.AppActiveUser.account.id)
        .then((response) => {
          if (response.data.length > 0) {
            this.payments = response.data;

            if (this.payments && Array.isArray(this.payments) && this.payments.length > 0) {
              this.payments.forEach(element => {
                element.tiptap_amount_commission_main_institution = element.base_amount_total - element.final_amount_collector_total
              });
            }

          }
        })
        .catch((error) => {
          console.log(error);
        });
      }

      if(this.$acl.check('store')) {
        this.$store.dispatch("reportModule/getMarketplacePaymentRequest",this.$store.state.AppActiveUser.store.id)
        .then((response) => {
          if (response.data.length > 0) {
            this.payments = response.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
      }
    },
    setColumnFilter(column, val) {
      const filter = this.gridApi.getFilterInstance(column)
      let modelObj = null

      if(val !== "all") {
        modelObj = { type: "equals", filter: val }
      }

      filter.setModel(modelObj)
      this.gridApi.onFilterChanged()
    },
    resetColFilters() {
      // Reset Grid Filter
      this.gridApi.setFilterModel(null)
      this.gridApi.onFilterChanged()

      // Reset Filter Options
      this.roleFilter = this.statusFilter = this.isVerifiedFilter = this.departmentFilter = { label: 'All', value: 'all' }

      this.$refs.filterCard.removeRefreshAnimation()
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val)
    },
    showPopup(data) {
      // ToDo: Get amount after taxes from service and render in popup table

      if (this.$acl.check('school')) {
        data.forEach(t => {
          const guardian_commissions = t.transaction_detail.find(d => d.code_item == "CSERVICE")
          const institution_commissions = t.transaction_detail.find(d => d.code_item == "CSERVICE2")

          t.percent_guardian = guardian_commissions ? guardian_commissions.percent_item : 0
          t.tiptap_amount_commission_guardian = guardian_commissions ? guardian_commissions.amount_item : 0
          t.percent_main_institution = institution_commissions ? institution_commissions.percent_item : 0
          t.tiptap_amount_commission_main_institution = institution_commissions ? institution_commissions.amount_item : 0
        })
      }

      this.popupData = data
      this.popupActive = true
    },
    updatePopupActive(val) {
      this.popupActive = val
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if(this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(".ag-header-container")
      header.style.left = "-" + String(Number(header.style.transform.slice(11,-3)) + 9) + "px"
    }

    this.getPayments();
  },
  created() {
    if (!reportModule.isRegistered) {
      this.$store.registerModule("reportModule", reportModule);
      reportModule.isRegistered = true;
    }
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
.cellMayusc{
  text-transform: capitalize;
}
</style>
