<template>
    <vs-popup fullscreen title="Detalle" :active.sync="popupActiveLocal">
        <ag-grid-vue
            ref="popupDetails"
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="items"
            colResizeDefault="shift"
            :animateRows="true"
            :pagination="false"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl">
        </ag-grid-vue>
    </vs-popup>
</template>
<script>
import { AgGridVue } from "@ag-grid-community/vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

export default {
    props: {
        data: {
            type: Array,
            required: true
        },
        popupActive: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            gridOptions: {
                alignedGrids: [],
                suppressHorizontalScroll: true,
            },
            defaultColDef: {
                sortable: true,
                editable: false,
                resizable: true,
                suppressMenu: true
            },
            columnDefs: [
                {
                headerName: 'ID',
                field: 'id_digital_payment_transaction',
                filter: true,
                width: 120,
                // hide: this.$acl.check('school')
                },
                {
                headerName: 'Servicio',
                field: 'name_service',
                filter: true,
                },
                {
                headerName: 'Valor del Servicio',
                field: 'base_amount',
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.base_amount, '$ '),
                },
                {
                headerName: '% Comisión Acudiente',
                field: 'percent_guardian',
                filter: true,
                valueFormatter: params => this.$options.filters.currencyPercentage(params.data.percent_guardian, ' %'),
                },
                {
                headerName: '$ Comisión Acudiente',
                field: 'tiptap_amount_commission_guardian',
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.tiptap_amount_commission_guardian, '$ '),
                },
                {
                headerName: '% Comisión Escuela',
                field: 'percent_main_institution',
                filter: true,
                valueFormatter: params => this.$options.filters.currencyPercentage(params.data.percent_main_institution, ' %'),
                },
                {
                headerName: '$ Comisión Escuela',
                field: 'tiptap_amount_commission_main_institution',
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.tiptap_amount_commission_main_institution, '$ '),
                },
                {
                headerName: 'Total del Servicio',
                field: 'total',
                filter: true,
                valueFormatter: params => this.$options.filters.currencySign(params.data.base_amount + params.data.tiptap_amount_commission_guardian + params.data.tiptap_amount_commission_main_institution, '$ '),
                valueGetter: params => {
                    return params.data.base_amount + params.data.tiptap_amount_commission_guardian + params.data.tiptap_amount_commission_main_institution
                }
                },
            ],
            // popupActiveLocal: JSON.parse(this.popupActive)
        }
    },
    components: {
        AgGridVue,
    },
    computed: {
        popupActiveLocal: {
            get() {
                return JSON.parse(this.popupActive)
            },
            set() {
                this.$emit('updatePopupActive')
            }
        },
        items() {
            return this.data
        }
    },
}
</script>
